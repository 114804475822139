import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {translate} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ExceededLicenseTO} from 'api/entities';
import {ToastrService} from 'ngx-toastr';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {Alcedo7User} from '../../shared/entities/user/avelon-user.service';
import {StatusCheckService} from '../../shared/services/status-check.service';
import {isExceededLicenseError} from '../license/exceeded-license.static';
import {LimitLicenseComponent} from '../license/limit-license.component';

export class ErrorInterceptorHandle {
  constructor(
    private toastrService: ToastrService,
    private statusCheck: StatusCheckService,
    private oidcSecurityService: OidcSecurityService,
    private dialog: MatDialog
  ) {}

  private static getToastErrorMessage(response: HttpErrorResponse, data?: any): string {
    let toastMessage: string;
    if (data?.message) {
      toastMessage = data.message || data.status;
    } else if (response.error) {
      toastMessage = response.error.message || response.error.status || response.message;
    } else {
      toastMessage = response.message;
    }
    return toastMessage;
  }

  handleErrors(err: any): void {
    if (err instanceof HttpErrorResponse) {
      this.checkErrorCurrentUser(err);
      if (err.status > 0) {
        this.statusCheck.checkSystemState();

        if (err.error && err.error instanceof Blob) {
          this.handleResponseTypeBlob(err);
        } else {
          this.handleErrorMessage(err);
        }
      }
    }
  }

  private checkErrorCurrentUser(err: HttpErrorResponse): void {
    if ((err.status === 401 || err.status === 404) && err.url.indexOf('users/current') > -1) {
      UserSessionService.destroy();
      this.oidcSecurityService.logoff().subscribe();
    }
  }

  private handleResponseTypeBlob(response: HttpErrorResponse): void {
    const self = this;
    const file = new FileReader();
    file.readAsText(response.error);
    file.onload = () => {
      try {
        self.handleErrorMessage(response, JSON.parse(file.result as string));
      } catch (e) {
        self.handleErrorMessage(response);
      }
    };
  }

  private handleErrorMessage(response: HttpErrorResponse, data?: any): void {
    if (StatusCheckService.isOffline() || UserSessionService.isReporting) {
      return;
    }

    const toastMessage = ErrorInterceptorHandle.getToastErrorMessage(response, data);
    if (isExceededLicenseError(response)) {
      const license = [response.error.properties.EXCEEDED_LICENSE_INFO];
      const body = toastMessage + '\n\n' + translate(marker('LICENSE.MESSAGE.SEE_EXCEEDED_PACKAGES'));
      this.closeDuplicateToast(body);
      this.toastrService
        .warning(body, translate(marker('LICENSE.TITLE.LICENSES_EXCEEDED')), {disableTimeOut: !Alcedo7User.currentUser.root})
        .onTap.subscribe(() => this.showLicenseLimitDialog(license));
    } else {
      this.closeDuplicateToast(toastMessage);
      this.toastrService.error(toastMessage, translate(marker('GENERAL.TITLE.ERROR')));
    }
  }

  private closeDuplicateToast(message: string) {
    if (this.toastrService.currentlyActive) {
      this.toastrService.toasts.filter(t => t.message === message).forEach(t => this.toastrService.remove(t.toastId));
    }
  }

  private showLicenseLimitDialog(exceededLicenseData: ExceededLicenseTO[]) {
    this.dialog.open(LimitLicenseComponent, {
      width: '700px',
      data: {
        exceededLicenseData
      }
    });
  }
}
