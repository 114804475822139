import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {apiVersion} from '../../api-version.constant';
import {SUPPRESS_ERROR_TOAST} from '../../shared/entities/shared.service';
import {StatusCheckService} from '../../shared/services/status-check.service';
import {ErrorInterceptorHandle} from './error-interceptor-handle';

export function errorInterceptor(request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> {
  if (!request.url.startsWith(apiVersion) || request.context.get(SUPPRESS_ERROR_TOAST)) {
    return handle(request);
  }

  const toastrService: ToastrService = inject(ToastrService);
  const statusCheck: StatusCheckService = inject(StatusCheckService);
  const oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  const dialog: MatDialog = inject(MatDialog);
  return handle(request).pipe(
    tap({error: err => new ErrorInterceptorHandle(toastrService, statusCheck, oidcSecurityService, dialog).handleErrors(err)})
  );
}
