import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {apiVersion} from '../../api-version.constant';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';

/** Auth interceptor used for Reporting and Slideshow special authorization */
export function specialAuthInterceptor(request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> {
  if (!request.url.startsWith(apiVersion)) {
    return handle(request);
  }

  return handle(addAuthTokenHeader(request));
}

function addAuthTokenHeader(req: HttpRequest<any>): HttpRequest<any> {
  return req.clone({setHeaders: UserSessionService.getHeaderToken()});
}
