import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {TranslocoModule} from '@jsverse/transloco';
import {Subscription} from 'rxjs';
import {PanelStatus, PanelStatusInfoTo} from 'types';
import {PanelServerStatusComponent} from '../../access-control/panel-import/panel-importing-progress/panel-server-status.component';
import {PanelInfoGQL} from '../../panel.generated';
import {DialogTitleComponent} from '../../shared/components/dialog-title/dialog-title.component';

@Component({
  selector: 'alc-panel-status-dialog',
  standalone: true,
  imports: [DialogTitleComponent, MatDialogModule, PanelServerStatusComponent, TranslocoModule],
  templateUrl: './panel-status-dialog.component.html',
  styleUrls: ['./panel-status-dialog.component.scss']
})
export class PanelStatusDialogComponent implements OnDestroy {
  PanelStatus = PanelStatus;

  private panelStatus$: Subscription;

  constructor(
    private dialogRef: MatDialogRef<PanelStatusDialogComponent>,
    private panelInfoGQL: PanelInfoGQL,
    @Inject(MAT_DIALOG_DATA) public dialogData: PanelStatusInfoTo
  ) {
    this.panelStatus$ = this.panelInfoGQL.subscribe().subscribe(response => {
      this.dialogData = response.data.panelInfo;
      if (this.dialogData.panelStatus !== PanelStatus.Importing && this.dialogData.panelStatus !== PanelStatus.Error) {
        this.dialogRef.close();
        location.reload();
      }
    });
  }

  ngOnDestroy(): void {
    this.panelStatus$.unsubscribe();
  }
}
