import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TrimmingInterceptorHandle} from './trimming-interceptor-handle';

export function trimInterceptor(request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> {
  const requestMethod = request.headers.get('behaveAsGET') ? 'GET' : request.method;
  if (requestMethod === 'GET') {
    return handle(request);
  }

  TrimmingInterceptorHandle.trimProperties(request.body, new TrimmingInterceptorHandle().getExceptions(request.urlWithParams));
  return handle(request);
}
