import {Component} from '@angular/core';
import {CgBusyDirective} from 'angular-busy2';
import {AvelonCloudHost} from '../../../shared/services/brand.service';
import {panelLoadingStatus} from '../../guards/panel-status.guard';

@Component({
  selector: 'alc-splash-screen',
  standalone: true,
  templateUrl: './splash-screen.component.html',
  imports: [CgBusyDirective],
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent {
  isPoweredBy = location.hostname !== AvelonCloudHost;
  panelLoadingStatus = panelLoadingStatus;
}
