import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ServiceUnavailableDialogComponent} from '../../core/service-unavailable-dialog/service-unavailable-dialog.component';
import {DEFAULT_DIALOG_WIDTH} from '../constants/dialog.constant';

@Injectable({providedIn: 'root'})
export class StatusCheckService {
  private static CHECK_PERIOD = 15 * 1000; // every 15 seconds

  private dialogRef: MatDialogRef<ServiceUnavailableDialogComponent> = null;
  private intervalId: number;

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  static isOffline() {
    return navigator.onLine === false;
  }

  startCheck() {
    if (!this.intervalId) {
      this.checkSystemState();
      this.intervalId = setInterval(() => this.checkSystemState(), StatusCheckService.CHECK_PERIOD) as any;
    }
  }

  stopCheck() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  checkSystemState() {
    if (StatusCheckService.isOffline()) {
      if (!this.dialogRef) {
        this.showDialog();
      }
    } else {
      this.stopCheck();
    }
  }

  private showDialog() {
    this.dialogRef = this.dialog.open(ServiceUnavailableDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      width: DEFAULT_DIALOG_WIDTH
    });
  }
}
