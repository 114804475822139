import { __extends } from "tslib";
import { Observable } from "../../utilities/index.js";
import { ApolloLink } from "../core/index.js";
export function onError(errorHandler) {
  return new ApolloLink(function (operation, forward) {
    return new Observable(function (observer) {
      var sub;
      var retriedSub;
      var retriedResult;
      try {
        sub = forward(operation).subscribe({
          next: function (result) {
            if (result.errors) {
              retriedResult = errorHandler({
                graphQLErrors: result.errors,
                response: result,
                operation: operation,
                forward: forward
              });
              if (retriedResult) {
                retriedSub = retriedResult.subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                });
                return;
              }
            }
            observer.next(result);
          },
          error: function (networkError) {
            retriedResult = errorHandler({
              operation: operation,
              networkError: networkError,
              //Network errors can return GraphQL errors on for example a 403
              graphQLErrors: networkError && networkError.result && networkError.result.errors,
              forward: forward
            });
            if (retriedResult) {
              retriedSub = retriedResult.subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              });
              return;
            }
            observer.error(networkError);
          },
          complete: function () {
            // disable the previous sub from calling complete on observable
            // if retry is in flight.
            if (!retriedResult) {
              observer.complete.bind(observer)();
            }
          }
        });
      } catch (e) {
        errorHandler({
          networkError: e,
          operation: operation,
          forward: forward
        });
        observer.error(e);
      }
      return function () {
        if (sub) sub.unsubscribe();
        if (retriedSub) sub.unsubscribe();
      };
    });
  });
}
var ErrorLink = /** @class */function (_super) {
  __extends(ErrorLink, _super);
  function ErrorLink(errorHandler) {
    var _this = _super.call(this) || this;
    _this.link = onError(errorHandler);
    return _this;
  }
  ErrorLink.prototype.request = function (operation, forward) {
    return this.link.request(operation, forward);
  };
  return ErrorLink;
}(ApolloLink);
export { ErrorLink };
