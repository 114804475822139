import {ApplicationRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet, UrlTree} from '@angular/router';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {TranslocoService} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CgBusyDefaults} from 'angular-busy2';
import {ToastrService} from 'ngx-toastr';
import {combineLatest, concat, interval, merge, Observable, of, retry} from 'rxjs';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {PanelStatus} from 'types';
import {environment} from '../environments/environment';
import {apiVersion} from './api-version.constant';
import {PanelStatusDialogComponent} from './client/panel-status-dialog/panel-status-dialog.component';
import {AuthService} from './core/auth/auth.service';
import {checkPanelStatus, panelStatus} from './core/guards/panel-status.guard';
import {getCustomHttpClient} from './core/interceptors/custom-http-caching.interceptor';
import {SplashScreenComponent} from './core/landing-page/splash-screen/splash-screen.component';
import {PanelStatusService} from './panel-status.service';
import {LocalStorageKeys} from './shared/constants/local-storage-keys.constant';
import {PanelQueryParams} from './shared/constants/query-params.constant';
import {UserSessionService} from './shared/entities/auth/user-session.service';
import {ErrorsLogEntity} from './shared/entities/errors-log/error-log.entity';
import {SystemInfoI} from './shared/entities/system/system.interface';
import {Alcedo7User, selectedClientChange$} from './shared/entities/user/avelon-user.service';
import {HelpService} from './shared/services/help.service';
import {LocalStorageService} from './shared/services/local-storage.service';
import {checkDisplayToast} from './shared/services/release-version';
import {StatusCheckService} from './shared/services/status-check.service';
import {ThemingService} from './shared/services/theming.service';
import {Brand, getThemingBrand, setBrandThemeColors} from './shared/services/theming.static';

@Component({
  selector: 'alc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, SplashScreenComponent]
})
export class AppComponent implements OnInit {
  @ViewChild('customBusyTemplate', {static: true})
  private set customBusyTemplateTpl(customBusyTemplateTpl: TemplateRef<any>) {
    this.busyDefaults.templateRef = customBusyTemplateTpl;
  }

  private dialogRef: MatDialogRef<PanelStatusDialogComponent>;

  constructor(
    public translate: TranslocoService,
    private busyDefaults: CgBusyDefaults,
    private updates: SwUpdate,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private appRef: ApplicationRef,
    private toastrService: ToastrService,
    private errorsLogEntity: ErrorsLogEntity, // needed to init the error log listener
    private authService: AuthService, // needed to init the oidc events listener
    private statusCheckService: StatusCheckService,
    private themingService: ThemingService,
    private oidcSecurityService: OidcSecurityService,
    public viewContainerRef: ViewContainerRef, // needed for alc-tooltip
    private panelStatusService: PanelStatusService,
    private dialog: MatDialog
  ) {
    HelpService.translate = translate;
    setBrandThemeColors();

    this.initTitle();

    this.initServiceWorkerChecks();

    window.addEventListener('online', () => this.statusCheckService.stopCheck());
    window.addEventListener('offline', () => this.statusCheckService.startCheck());

    router.events.subscribe(e => {
      if (e instanceof NavigationError && e?.url.startsWith('/?state=')) {
        // Error: Uncaught (in promise): could not find matching config for state.
        // This happens when resetting the password. If the user opens the email link in a new browser tab,
        // the oidc library should authorize() again instead of throwing an error.
        location.href = '/';
      }
      if (e instanceof NavigationStart) {
        dialog.openDialogs.forEach(dialogRef => {
          if (!['releaseNotes', 'panelStatusDialog', 'billingAddressDialog'].includes(dialogRef.id)) {
            dialogRef.close();
          }
        });
      }
    });
    this.checkDeploymentStatus();
  }

  ngOnInit(): void {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const isPanelMachine = params.get(PanelQueryParams.isPanel);
      const panelMachineUrl = params.get(PanelQueryParams.panelUrl);
      if (isPanelMachine === 'true') {
        localStorage.setItem(LocalStorageKeys.Panel.machineIsPanel, isPanelMachine);
        localStorage.setItem(LocalStorageKeys.Panel.machinePanelUrl, panelMachineUrl);
      }
    }
    this.themingService.darkTheme.subscribe((darkThemeEnabled: boolean) => {
      document.body.classList.toggle('avelon-dark-theme', darkThemeEnabled);
    });
  }

  private initTitle(): void {
    const routeChange$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

    merge(routeChange$, selectedClientChange$)
      .pipe(switchMap(() => this.getFirstPartTitle()))
      .subscribe(firstPart => this.updateTitle(firstPart));
  }

  private getFirstPartTitle(): Observable<string | undefined> {
    let title: string;
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
      if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.title) {
        title = route.routeConfig.data.title;
      }
    }
    if (title) {
      return this.translate.selectTranslate(title).pipe(
        map(firstPart => {
          if (firstPart === title) {
            return undefined;
          } else {
            return firstPart;
          }
        })
      );
    } else {
      return of(undefined);
    }
  }

  private updateTitle(firstPart: string | undefined): void {
    let secondPart = getThemingBrand() === Brand.WAGO ? 'WAGO' : 'Avelon';
    if (Alcedo7User.selectedClient) {
      secondPart = Alcedo7User.selectedClient.name;
    }
    if (firstPart && secondPart) {
      this.title.setTitle(`${firstPart} - ${secondPart}`);
    } else if (firstPart) {
      this.title.setTitle(firstPart);
    } else if (secondPart) {
      this.title.setTitle(secondPart);
    }
  }

  private initServiceWorkerChecks(): void {
    if (this.updates.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(2 * 60 * 60 * 1000); // check every 2 hours
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());

      this.updates.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(event => checkDisplayToast(event, environment.version, () => this.displayUpdateToast()));
    }
  }

  private displayUpdateToast(): void {
    const titleTranslate = this.translate.selectTranslate('GENERAL.UPDATE.TITLE');
    const bodyTranslate = this.translate.selectTranslate('GENERAL.UPDATE.MESSAGE');
    combineLatest([titleTranslate, bodyTranslate]).subscribe(response => {
      if (location.pathname.includes('slides') || document.body.className.includes('full-screen-mode')) {
        this.updates.activateUpdate().then(() => document.location.reload());
      } else {
        this.toastrService
          .info(response[1], response[0], {disableTimeOut: true})
          .onTap.subscribe(() => this.updates.activateUpdate().then(() => document.location.reload()));
      }
    });
  }

  private checkDeploymentStatus(): void {
    const http = getCustomHttpClient();
    http
      .get('health/entities')
      .pipe(
        retry({delay: 5000}),
        switchMap(() => http.get<SystemInfoI>(`${apiVersion}system/staticInfo`)),
        filter(response => UserSessionService.isOnSitePanel(response.deploymentType))
      )
      .subscribe(() => this.handlePanelStates());
  }

  private handlePanelStates(): void {
    this.overrideEventsForPanelKiosk();
    // Needed for when the panel is unlinked or synchronizing data.
    this.panelStatusService.subscribe().subscribe(response => {
      this.checkAccessTokenExpiration(response.data.panelInfo.panelStatus);
      const route: boolean | UrlTree = checkPanelStatus(this.router, location.href.includes('client/'))(response);
      if (route instanceof UrlTree) {
        this.router.navigateByUrl(route);
      } else if (
        [PanelStatus.Importing, PanelStatus.Error].includes(response.data.panelInfo.panelStatus) &&
        !this.dialogRef &&
        LocalStorageService.getItemWithExpiration(LocalStorageKeys.Panel.ignoreImportErrors) !== 'true'
      ) {
        this.dialogRef = this.dialog.open(PanelStatusDialogComponent, {
          id: 'panelStatusDialog',
          autoFocus: 'dialog',
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: false,
          data: response.data.panelInfo
        });
        this.dialogRef.afterClosed().subscribe(() => (this.dialogRef = null));
      }
    });
  }

  private checkAccessTokenExpiration(status: PanelStatus): void {
    // If the user is logged in, the new status is importing and the old status is different from importing,
    // force refresh token only on the first received import status and reset it after.
    if (Alcedo7User.currentUser && status === PanelStatus.Importing && panelStatus.status !== PanelStatus.Importing) {
      this.oidcSecurityService.getPayloadFromAccessToken().subscribe(payload => {
        // Check for expiration time of the accessToken.
        // If the token expires in under 20 minutes, force a refresh token before synchronization starts.
        if (payload.exp * 1000 - Date.now() < 1000 * 60 * 20) {
          this.oidcSecurityService.forceRefreshSession().subscribe();
        }
      });
    }
  }

  private overrideEventsForPanelKiosk(): void {
    // Hackish way to override opening new tabs for Lumina Operation Center devices.
    // Override the click event on all anchor elements with target '_blank' to open in the same tab.
    document.onclick = (event: MouseEvent): void => {
      const anchor = findAnchorElement(event.target as HTMLElement);
      if (anchor && anchor.href && anchor.getAttribute('target') === '_blank') {
        event.stopPropagation();
        event.preventDefault();
        location.href = anchor.href;
      }
    };
    const findAnchorElement = (el: HTMLElement): HTMLAnchorElement | null =>
      (el instanceof HTMLAnchorElement && el) || (el.parentElement && findAnchorElement(el.parentElement));

    // Override any JavaScript code that uses window.open() to open a link.
    const originalOpen = window.open;
    window.open = (url, target, windowFeatures) => originalOpen(url, '_self', windowFeatures);
  }
}
