import {Component} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslocoModule} from '@jsverse/transloco';
import {DialogTitleComponent} from '../../shared/components/dialog-title/dialog-title.component';

@Component({
  selector: 'alc-service-unavailable-dialog',
  templateUrl: './service-unavailable-dialog.component.html',
  styleUrls: ['./service-unavailable-dialog.component.scss'],
  standalone: true,
  imports: [DialogTitleComponent, MatDialogModule, MatIconModule, TranslocoModule]
})
export class ServiceUnavailableDialogComponent {}
