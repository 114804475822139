import {inject} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// This is needed in order to avoid initializing the translated labels before the translation files are available.
export function translocoLoaderGuard(): Observable<boolean> {
  const translocoService = inject(TranslocoService);
  return translocoService.load(translocoService.getActiveLang()).pipe(map(() => true));
}
