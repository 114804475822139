import { __rest } from "tslib";
import { ApolloLink } from "../core/index.js";
import { Observable } from "../../utilities/index.js";
export function setContext(setter) {
  return new ApolloLink(function (operation, forward) {
    var request = __rest(operation, []);
    return new Observable(function (observer) {
      var handle;
      var closed = false;
      Promise.resolve(request).then(function (req) {
        return setter(req, operation.getContext());
      }).then(operation.setContext).then(function () {
        // if the observer is already closed, no need to subscribe.
        if (closed) return;
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      }).catch(observer.error.bind(observer));
      return function () {
        closed = true;
        if (handle) handle.unsubscribe();
      };
    });
  });
}
