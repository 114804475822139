import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CONFIG} from './charts/core/animation-duration.const';
import {UserSessionService} from './shared/entities/auth/user-session.service';
import {WidgetRoutingMap} from './shared/entities/widgets/widgets.constant';

@Component({
  selector: 'alc-export',
  template: '',
  standalone: true
})
export class ExportComponent {
  constructor(private router: Router, private route: ActivatedRoute) {
    const params = route.snapshot.params;
    UserSessionService.setToken(params.token);

    document.querySelector('body').classList.add('reporting-engine');
    CONFIG.animationDuration = 0;
    UserSessionService.isReporting = true;
    let commands: any[];
    if (params.widgetType) {
      commands = [
        'reporting',
        'client',
        params.clientId,
        'dashboards',
        'group',
        params.groupId,
        'dashboard',
        params.dashboardId,
        WidgetRoutingMap[params.widgetType],
        params.widgetId,
        'configuration',
        'false'
      ];
    } else {
      commands = ['reporting', 'client', params.clientId, 'dashboards', 'group', params.groupId, 'dashboard', params.dashboardId];
    }
    router.navigate(commands);
  }
}
